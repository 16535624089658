/* eslint-disable no-undef */
import React, { useState } from "react";
//import { Container, Row, Col } from "react-bootstrap";
import { MDBIcon, MDBContainer, MDBRow, MDBCol } from "mdbreact";
import { FormatDateOnly } from "../../helpers/formatters";

import Shuffle from "shufflejs";
import PropTypes from "prop-types";

// import SadFace from '../../images/icons/nao-encontrado.svg'
import { MDBBtn, MDBBtnGroup } from "mdbreact";

import CardAviso from "../CardAviso/CardAviso";

//import { ConvertInstitutoToArea } from "../../helpers/Formatters";

/**
 * Botão de inscrições para os cursos de pós-graduação.
 * @constructor
 * @param {Array<object>} avisos - Lista de objetos de avisos.
 */

export default class FiltroAvisos extends React.Component {
	constructor(props) {
		super(props);
		this.avisos = props.avisos;
	}

	// Lógica do filtro front-end.
	componentDidMount() {
		// Componente que realiza a filtragem no DOM.
		class CustomFilter {
			constructor(element) {
				this.shuffle = new Shuffle(element, {
					easing: "cubic-bezier(0.165, 0.840, 0.440, 1.000)",
					gutterWidth: 0,
				});

				this.tipos = Array.from(
					document.querySelectorAll("#avisos-tipo-filtro button")
				);

				this.filters = {
					titulacoes: [],
				};

				this.searchBar = document.querySelector("#avisos-busca-filtro");

				// Caso não tenha nenhum item.
				this.shuffle.on(Shuffle.EventType.LAYOUT, function (e) {
					if (e.shuffle.visibleItems === 0) {
						document.querySelector("#avisos-items-noitems").style.display =
							"block";
					} else {
						document.querySelector("#avisos-items-noitems").style.display =
							"none";
					}
				});

				this._bindEventListeners();
			}

			_bindEventListeners() {
				this._onTipoChange = this._handleTipoChange.bind(this);

				this.tipos.forEach(function (button) {
					button.addEventListener("click", this._onTipoChange);
				}, this);

				this.searchBar.addEventListener(
					"keyup",
					this._handleSearchKeyup.bind(this)
				);
			}

			// ---- TIPO ----
			_getCurrentTipoFilters() {
				return this.tipos
					.filter(function (button) {
						return button.classList.contains("active");
					})
					.map(function (button) {
						return button.getAttribute("data-tipo");
					});
			}

			_handleTipoChange(evt) {
				var button = evt.currentTarget;

				document.querySelector("#avisos-tipo-filtro").value = "";
				// Treat these buttons like radio buttons where only 1 can be selected.
				if (button.classList.contains("active")) {
					button.classList.remove("active");
				} else {
					this.tipos.forEach(function (btn) {
						btn.classList.remove("active");
					});

					button.classList.add("active");
				}

				this.filters.tipos = this._getCurrentTipoFilters();
				this.filter();
			}

			// Executa os filtros.
			filter() {
				if (this.hasActiveFilters()) {
					this.shuffle.filter(this.itemPassesFilters.bind(this));
				} else {
					this.shuffle.filter(Shuffle.ALL_ITEMS);
				}
			}

			// Valida se existe algum filtro ativo.
			hasActiveFilters() {
				return Object.keys(this.filters).some(function (key) {
					return this.filters[key].length > 0;
				}, this);
			}

			// Valida se o objeto passa pelo filtro.
			itemPassesFilters(element) {
				var tipos = this.filters.tipos;

				var tipo = element.getAttribute("data-tipo");

				if (tipos.length > 0 && !tipo.includes(tipos)) {
					return false;
				}

				return true;
			}

			// Filtra os títulos pela busca.
			_handleSearchKeyup(evt) {
				var searchText = evt.target.value
					.toLowerCase()
					.normalize("NFD")
					.replace(/[\u0300-\u036f]/g, "");

				this.shuffle.filter(function (element) {
					var titleElement = element.getAttribute("data-name");

					var titleText = titleElement
						.normalize("NFD")
						.replace(/[\u0300-\u036f]/g, "")
						.toLowerCase()
						.trim();

					return titleText.indexOf(searchText) !== -1;
				});
			}
		}

		// Instancia o componente
		new CustomFilter(document.getElementById("avisos-items-container"));
	}

	render() {
		return (
			<MDBContainer fluid>
				<MDBRow className="text-center">
					<MDBCol>
						<div className="input-group md-form form-1">
							<div className="input-group-prepend">
								<span className="input-group-text bg-green" id="basic-text1">
									<MDBIcon className="text-white" icon="search" />
								</span>
							</div>
							<input
								className="form-control my-0 py-1"
								aria-label="Pesquisar"
								id="avisos-busca-filtro"
								type="text"
								placeholder="Pesquisar"
							/>
						</div>
					</MDBCol>
				</MDBRow>

				<MDBRow className="text-center mb-2">
					<MDBCol>
						<h4> Categoria </h4>
						<MDBBtnGroup
							size="middle"
							className="btn-group-filtro flex-wrap"
							id="avisos-tipo-filtro"
							aria-label="Aviso de qual parte da instituição?"
						>
							{[
								"Editais",
								"Escola de Aplicação",
								"Feevale Up",
								"Feevale Way",
								"International",
								"Institucional",
								"Pos Lato",

							].map((item) => (
								<MDBBtn
									className="mx-1 my-1 px-2 px-md-3 rounded btn-yellow"
									data-tipo={item}
									key={item}
								>
									<MDBIcon icon="chevron-circle-right" aria-hidden="true" />{" "}
									{item}
								</MDBBtn>
							))}
						</MDBBtnGroup>
					</MDBCol>
				</MDBRow>

				<MDBRow id="avisos-items-container" className="no-gutters">
					{this.props.avisos.map((aviso) => {
						let dataFim = new Date(aviso.DataFinal);
						dataFim.setDate(dataFim.getDate() + 1);
						
						if (
							Date.parse(dataFim) >= Date.now() &&
							Date.parse(aviso.DataInicial) <= Date.now()
						) {
							return (
								<MDBCol
									md={6}
									col={12}
									className="p-1 aspect aspect--4x3"
									key={aviso.Titulo}
									data-tipo={aviso.Tipo}
									data-name={aviso.Titulo}
								>
									<CardAviso
										key={aviso.Titulo}
										tipo={aviso.Tipo}
										name={aviso.Titulo}
										slug={aviso.Slug}
										ultimaModificacao={aviso.UltimaModificacao}
										redirecionamento={aviso.Redirecionamento}										
									/>
								</MDBCol>
							);
						}
					})}
				</MDBRow>
				<MDBRow
					id="avisos-items-noitems"
					className="text-center my-4 linear-box mx-5 py-4"
					style={{ display: "none" }}
				>
					<MDBCol>
						<p>
							Não temos um aviso nessas características. Registre seu interesse.
						</p>
					</MDBCol>
				</MDBRow>
			</MDBContainer>
		);
	}
}
